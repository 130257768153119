import React from 'react';
import { graphql } from "gatsby";

import Layout from '../../components/layout.js';
import SEO from '../../components/seo.js';
import PageHeading from '../../components/page-heading.js';
import CaseStudyTile from '../../components/case-study-tile.js';
import '../../styles/article.scss';

const Blog = ({data}) => {

    const articles = [
        {
            client: "Jubel",
            tags: ['Design', 'Development', 'SEO'],
            blurb: "How 54 Bit Studios helped Jubel optimisie their website for scalability, performance and enhanced customer engagement.",
            image: data.allFile.nodes.find(node => node.name === "jubel-beer") ? data.allFile.nodes.find(node => node.name === "jubel-beer").childImageSharp.gatsbyImageData : null,
            link: '/case-studies/jubel',
            type: 'Case Study'
        },
        {
            client: "Black Friday is around the corner, is your Shopify store ready?",
            tags: ['CRO', 'SEO'],
            blurb: "Black Friday is just around the corner, are you ready? We've put together a list of things you can do to prepare your Shopify store for the busiest shopping period of the year.",
            image: data.allFile.nodes.find(node => node.name === "black-friday-sign") ? data.allFile.nodes.find(node => node.name === "black-friday-sign").childImageSharp.gatsbyImageData : null,
            link: '/blog/black-friday-is-around-the-corner-is-your-shopify-store-ready',
            type: 'Article'
        },
        {
            client: "Russell & Atwell",
            tags: ['Design', 'Development', 'SEO', 'CRO'],
            blurb: "How 54 Bit Studios helped Russell & Atwell elevate their website for faster speed, improved efficiency, and a seamless customer experience.",
            image: data.allFile.nodes.find(node => node.name === "chocolates") ? data.allFile.nodes.find(node => node.name === "chocolates").childImageSharp.gatsbyImageData : null,
            link: '/case-studies/russell-and-atwell',
            type: 'Case Study'
        },
        {
            client: "Black Friday: Shopify Checklist and Tips",
            tags: ['Development', 'CRO', 'SEO'],
            blurb: "Discover our last minute must-dos to ensure ecommerce success for your Shopify Plus store this Black Friday.",
            image: data.allFile.nodes.find(node => node.name === "speed-report") ? data.allFile.nodes.find(node => node.name === "speed-report").childImageSharp.gatsbyImageData : null,
            link: '/blog/black-friday-shopify-checklist-and-tips',
            type: 'Article'
        },
        {
            client: "Unlock 25% More Sales This Black Friday",
            tags: ['Development', 'CRO', 'SEO'],
            blurb: "Discover essential Shopify optimisations to boost website speed, mobile usability, checkout efficiency and more to capitalise on Black Friday.",
            image: data.allFile.nodes.find(node => node.name === "black-friday") ? data.allFile.nodes.find(node => node.name === "black-friday").childImageSharp.gatsbyImageData : null,
            link: '/blog/unlock-25-more-sales-this-black-friday-the-ultimate-shopify-optimisation-guide',
            type: 'Article'
        },
        {
            client: "COAT Paints",
            tags: ['Development', 'SEO', 'Analytics'],
            blurb: "How 54 Bit Studios transformed the COAT Paints website to boost conversion rates and meet the demands of their rapidly expanding customer base.",
            image: data.allFile.nodes.find(node => node.name === "coat-tins") ? data.allFile.nodes.find(node => node.name === "coat-tins").childImageSharp.gatsbyImageData : null,
            link: '/case-studies/coat-paints',
            type: 'Case Study'
        },
        {
            client: "Custom vs Off-the-shelf Shopify Themes: The pros and cons",
            tags: ['Development', 'Design', 'SEO'],
            blurb: "Are off-the-shelf Shopify Themes or custom websites right for you? Discover the pros and cons to help you make an informed decision.",
            image: data.allFile.nodes.find(node => node.name === "off-the-shelf") ? data.allFile.nodes.find(node => node.name === "off-the-shelf").childImageSharp.gatsbyImageData : null,
            link: '/blog/off-the-shelf-shopify-themes-the-pros-and-cons',
            type: 'Article'
        }
    ]
    
    console.log(data.allFile.nodes);

    return (
        <Layout background="stone">
            <SEO title="54 Bit Studios Blog" />
            <PageHeading h1="The Blog" h2="" blurb="Discover our latest articles and insights for Shopify and Shopify Plus stores, including case studies, optimisation guides, and more..." />
            <section className='ff case-studies blog' id='case-studies'>
                <div className="grid">
                    {articles.map(article => (
                        <CaseStudyTile
                            key={article.client}
                            image={article.image}
                            client={article.client}
                            blurb={article.blurb}
                            tags={article.tags}
                            link={article.link}
                            type={article.type}
                        />
                    ))}
                </div>
            </section>
        </Layout>
    );
};

export default Blog;

export const query = graphql`
    query {
        allFile(filter: { sourceInstanceName: { eq: "assets" } }) {
            nodes {
                name
                relativePath
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 1200)
                }
            }
        }
    }
`;